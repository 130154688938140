export function parseDatetimeToLocal(datetime) {
  datetime = new Date(datetime);
  const localDatetime = new Date(
    datetime.getTime() - datetime.getTimezoneOffset() * 60000,
  );
  return localDatetime.toISOString().slice(0, 19).replace("T", " ");
}

export function dateTimeISOString(datetime_str) {
  var datetime = new Date(datetime_str);
  return datetime.toISOString();
}

export function dateTimeOrNull(
  dateString,
  isDateTime = true,
  locale = "sk-SK",
) {
  if (dateString === null || dateString === undefined || dateString === "") {
    return null;
  }
  const date = new Date(dateString);
  if (!isNaN(date.getTime())) {
    return Intl.DateTimeFormat(
      locale,
      isDateTime
        ? {
            dateStyle: "short",
            timeStyle: "medium",
          }
        : {},
    ).format(date);
  } else {
    return null;
  }
}
