import {
  getPatientsMedicationsApiV1PatientsPatientIdMedicationsGet,
  getSymptomsApiV1HfSymptomsPatientIdGet,
} from "./api";
import { ApiConnector } from "./apiConnector";
import {
  Computed,
  consentOk,
  DerivedData,
  Diagnoses,
  Events,
  isPaperConsent,
  MedData,
  Medications,
  MergedMedData,
  PPGs,
  Schedules,
  Symptoms,
  Thresholds,
} from "./dataUtils";
import { ComputedPlot, MedicalPlot, SymptomPlot } from "./plotting";
import { dateTimeISOString, dateTimeOrNull } from "./utils";

export class Patient {
  patientId: number = null;
  loaded = false;
  data: any = null;
  vitals: MergedMedData = null;
  symptoms: Symptoms = null;
  events: Events = null;
  medications: Medications = null;
  diags: Diagnoses = null;
  labs: MedData = null;
  exams: MedData = null;
  basicMed: MedData = null;
  ppgs: PPGs = null;
  schedules: Schedules = null;
  physicianMessage: any = null;
  heartCore: any = null;

  diagOptions: Record<string, string[]> = {};
  minDRIHeartCore = 4;

  driData: Computed;
  driDataPlot: ComputedPlot;
  vitalsPlot: MedicalPlot;
  symptomPlot: SymptomPlot;
  thresholds: Thresholds;
  labsPlot: MedicalPlot;
  basicMedPlot: MedicalPlot;

  constructor(public api: ApiConnector) {}

  async fetchBasic(patientId: number) {
    this.patientId = patientId;
    await this.api.checkLoggedIn();
    this.data = await this.api.get(`patients/${patientId}`);
  }

  async fetch(patientId: number) {
    this.patientId = patientId;
    await this.api.checkLoggedIn();
    this.data = await this.api.get(`patients/${patientId}`);

    // DRI
    const allComputed = await this.api.get(
      `patients/${patientId}/computed?all_versions=true`,
    );
    this.driData = new Computed(allComputed.computed_data);
    this.driData.init();
    this.driData.filterDRI();
    this.driDataPlot = new ComputedPlot(
      this.driData,
      ["diastolic reserve index"],
      "driDataPlot",
    );
    this.driDataPlot.initPlot();

    // vitals
    const vitals = await this.api.get(`hf/vitals/${patientId}`);
    this.vitals = new MergedMedData(vitals.medical_data, this.api.amILevel3);
    // merge with SpO2 and HR from derived and HR from computed
    const derived = await this.api.get(`patients/${patientId}/derived`);
    const SpO2 = new DerivedData(derived.derived_data);
    SpO2.init();
    SpO2.filterSpO2();
    const HRDerived = new DerivedData(derived.derived_data);
    HRDerived.init();
    HRDerived.filterHR();

    const computed = await this.api.get(
      `patients/${patientId}/computed?all_versions=false`,
    );
    const HRComputed = new Computed(computed.computed_data);
    HRComputed.init();
    HRComputed.filterHR();

    const varMapping = {
      "SpO2: mean": "SpO2",
      "HR: mean": "heart rate",
      "heart rate": "heart rate",
    };
    this.vitals.mergeWithPPGDerivedAndComputed(
      [SpO2, HRDerived],
      [HRComputed],
      "measurement_type",
      varMapping,
    );
    this.vitals.init();
    this.vitalsPlot = new MedicalPlot(
      this.vitals,
      [
        "heart rate",
        "blood pressure diastolic",
        "blood pressure systolic",
        "SpO2",
      ],
      "vitalsPlot",
    );
    this.vitalsPlot.initPlot();

    // symptoms
    const symptoms = await getSymptomsApiV1HfSymptomsPatientIdGet({
      path: { patient_id: patientId },
      throwOnError: true,
    });
    this.symptoms = new Symptoms(symptoms.data.symptoms, this.api.amILevel3);
    this.symptoms.init();
    this.symptomPlot = new SymptomPlot(
      this.symptoms,
      ["shortness of breath", "fatigue score"],
      "symptomPlot",
    );
    this.symptomPlot.initPlot();

    // events and thresholds
    if (this.api.amILevel3) {
      this.events = new Events(this.data.events);
      await this.events.init();
      const defaultThresholds = await this.api.get("thresholds/default");
      this.thresholds = new Thresholds(
        this.data.thresholds,
        defaultThresholds.thresholds,
      );
      this.thresholds.init();
    }

    // medications
    const medications =
      await getPatientsMedicationsApiV1PatientsPatientIdMedicationsGet({
        path: { patient_id: patientId },
        throwOnError: true,
      });
    this.medications = new Medications(
      medications.data.medications,
      this.api.amILevel3,
    );
    this.medications.init();

    // comorbidities
    const diags = await this.api.get(`hf/comorbidities/${patientId}`);
    this.diags = new Diagnoses(diags.diagnoses, this.api.amILevel3);
    this.diags.init();
    await this.getDiagnosesOptions();

    // labs
    const labs = await this.api.get(`hf/lab-data/${patientId}`);
    this.labs = new MedData(labs.medical_data, this.api.amILevel3);
    this.labs.init();
    this.labsPlot = new MedicalPlot(
      this.labs,
      ["NT-proBNP", "BNP", "urea", "creatinine", "hemoglobin", "hematocrit"],
      "labsPlot",
    );
    this.labsPlot.initPlot();

    // exams
    const exams = await this.api.get(`hf/exams/${patientId}`);
    this.exams = new MedData(exams.medical_data, this.api.amILevel3);
    this.exams.init();

    // basic medical data
    const basicMed = await this.api.get(`hf/basic-med-data/${patientId}`);
    this.basicMed = new MedData(basicMed.medical_data, this.api.amILevel3);
    this.basicMed.init();
    this.basicMedPlot = new MedicalPlot(
      this.basicMed,
      ["weight", "dry weight", "temperature", "respiratory rate"],
      "basicMedPlot",
    );
    this.basicMedPlot.initPlot();

    // PPG data
    const ppgs = await this.api.get(`patients/${patientId}/ppgs`);
    this.ppgs = new PPGs(ppgs.ppg_data);
    this.ppgs.init();

    const schedules = await this.api.get(
      `schedules?patient_ids=${patientId}&schedule_type=ppg`,
    );
    this.schedules = new Schedules(schedules.schedules);
    this.schedules.init();

    this.physicianMessage = await this.api.get(`messaging/${patientId}`);

    if (this.api.amILevel3) {
      this.heartCore = this.data.heart_core;
      this.heartCore.last_ppg = dateTimeOrNull(this.heartCore.last_ppg, true);
      this.heartCore.reportStart = dateTimeOrNull(
        this.heartCore.report_start_date,
        false,
      );
      this.heartCore.dris = this.heartCore.last_5_dris.map(
        ([datetime, floatVal]) => [
          dateTimeOrNull(datetime),
          parseFloat(floatVal.toFixed(1)),
        ],
      );
    }

    this.loaded = true;
  }

  consentOk() {
    return consentOk(this.data);
  }

  isPaperConsent() {
    return isPaperConsent(this.data);
  }

  sanitizeBody(body: object, datetimeIso: string[] = []) {
    for (const key in body) {
      if (body.hasOwnProperty(key)) {
        if (body[key] === "") {
          body[key] = null;
        }
      }
      if (datetimeIso.includes(key) && body.hasOwnProperty(key)) {
        var datetime = body[key];
        body[key] = dateTimeISOString(datetime);
      }
    }
    // remove null
    body = Object.fromEntries(
      Object.entries(body).filter(([key, value]) => value !== null),
    );
    return body;
  }

  async updatePatientField(body: object) {
    const cleanBody = this.sanitizeBody(body);
    await this.api.put(`patients/${this.patientId}`, cleanBody);
    await this.fetch(this.patientId);
  }

  async updateItem(
    endpoint: string,
    uuid: string,
    body: object,
    datetimeIso: string[] = [],
  ) {
    const cleanBody = this.sanitizeBody(body, datetimeIso);
    await this.api.put(`${endpoint}/${uuid}`, cleanBody);
    this.loaded = false;
    await this.fetch(this.patientId);
    this.loaded = true;
  }

  async deleteItem(endpoint: string, uuid: string) {
    await this.api.delete(`${endpoint}/${uuid}`);
    this.loaded = false;
    await this.fetch(this.patientId);
    this.loaded = true;
  }

  async createConnectedUser(body: object) {
    await this.api.post(`patients/${this.patientId}/user`, body);
    this.loaded = false;
    await this.fetch(this.patientId);
    this.loaded = true;
  }

  async getDiagnosesOptions() {
    const response = await this.api.get("hf/comorbidities");
    this.diagOptions = response["comorbidities"];
  }

  numDRIDataPoints() {
    return this.driData.data.length;
  }

  floatRounding(value: number | string, decimals: number = 3) {
    if (typeof value === "number") {
      return value.toFixed(decimals);
    }
    return value;
  }
}
